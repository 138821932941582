import { createAsyncThunk } from '@reduxjs/toolkit';
import ShowToast from '../../../components/ShowToast/ShowToast';
import { JUPITER_API_URL,PLATFORM_FEE_ACCOUNT, PLATFORM_FEE_BPS } from '../../../constants/constants';
import { VersionedTransaction, Connection, PublicKey } from '@solana/web3.js';

export const swapTokensThunk = createAsyncThunk(
  'swap/swapTokens',
  async (
    {
      wallet,
      connection,
      tokenTop,
      tokenBottom,
      topAmount,
      slippage,
    }: {
      wallet: any;
      connection: Connection;
      tokenTop: { mintAddress: string; decimals: number };
      tokenBottom: { mintAddress: string; decimals: number };
      topAmount: string;
      slippage: number;
    },
    { rejectWithValue, dispatch }
  ) => {
    //console.log('Starting swapTokensThunk...');
    {/*
    console.log('Input parameters:', {
      wallet,
      connection,
      tokenTop,
      tokenBottom,
      topAmount,
      bottomAmount,
      slippage,
    });
    */}

    if (!wallet.connected) {
      ShowToast({
        type: 'error',
        title: 'Wallet Not Connected!',
        text: 'Please connect your wallet before swapping.',
      });
      return rejectWithValue('Wallet not connected');
    }

    try {
      // Step 1: Fetch SOL balance for transaction fees
      const solBalance = await connection.getBalance(wallet.publicKey);
      //console.log('Wallet SOL balance:', solBalance);
      if (solBalance < 1000000) {
        ShowToast({
          type: 'error',
          title: 'Insufficient SOL',
          text: 'You need more SOL to cover transaction fees.',
        });
        return rejectWithValue('Insufficient SOL for fees');
      }

      const inputAmountParsed = Math.floor(parseFloat(topAmount) * Math.pow(10, tokenTop.decimals));
      //console.log('Parsed topAmount into lamports:', inputAmountParsed);

      const slippageBps = slippage * 100;
      const inputMint = tokenTop.mintAddress;
      const outputMint = tokenBottom.mintAddress;
      const referralAccountPubkey = new PublicKey(PLATFORM_FEE_ACCOUNT);
      const referralProgramId = new PublicKey('REFER4ZgmyYx9c6He5XfaTMiGfdLwRnkV4RPp9t9iF3');

      // Find the fee account
      const [feeAccount] = await PublicKey.findProgramAddressSync(
        [
          Buffer.from('referral_ata'),
          referralAccountPubkey.toBuffer(),
          new PublicKey(inputMint).toBuffer(),
        ],
        referralProgramId
      );

      // Build the quote URL for the Jupiter API
      const quoteUrl = `${JUPITER_API_URL}/quote?inputMint=${inputMint}&outputMint=${outputMint}&amount=${inputAmountParsed}&slippageBps=${slippageBps}&platformFeeBps=${PLATFORM_FEE_BPS}&feeAccount=${feeAccount.toBase58()}`;

      // Fe
      //console.log('Fetching quote from:', quoteUrl);
      const quoteResponse = await fetch(quoteUrl);
      const quoteData = await quoteResponse.json();

      if (!quoteResponse.ok || !quoteData || !quoteData.routePlan || quoteData.routePlan.length === 0) {
        //console.log('No routes found for swap.');
        ShowToast({
          type: 'error',
          title: 'Swap Error',
          text: 'No routes found for swap.',
        });
        return rejectWithValue('No routes found for swap');
      }

      //console.log('Best route received:', quoteData.routePlan);
      //console.log("quote data is here", quoteData)
      //console.log("quoteResponse is here", quoteResponse)

      // Step 3: Submit swap request, including the routing plan
      const swapResponse = await fetch(`${JUPITER_API_URL}/swap`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          quoteResponse: quoteData,  // Pass the full quoteResponse object
          userPublicKey: wallet.publicKey.toString(),
          wrapAndUnwrapSol: true,
          dynamicComputeUnitLimit: true,  // Enable dynamic compute unit limit for multi-hop swaps
          prioritizationFeeLamports: 650000,  
        }),
      });
      
      
      if (!swapResponse.ok) {
        ShowToast({
          type: 'error',
          title: 'Swap Error',
          text: `Swap API failed with status: ${swapResponse.status}`,
        });
        return rejectWithValue(`Swap API failed with status: ${swapResponse.status}`);
      }

      const swapResponseData = await swapResponse.json();
      const { swapTransaction } = swapResponseData;

      if (!swapTransaction) {
        //console.log('No swap transaction found in response.');
        ShowToast({
          type: 'error',
          title: 'Swap Error',
          text: 'No swap transaction found in response.',
        });
        return rejectWithValue('No swap transaction found in response');
      }

      // Step 4: Deserialize the transaction and send it
      //console.log('Deserializing and sending swap transaction...');
      const transactionBuffer = Buffer.from(swapTransaction, 'base64');
      const transaction = VersionedTransaction.deserialize(new Uint8Array(transactionBuffer));

      const signature = await wallet.sendTransaction(transaction, connection);
      //console.log('Transaction sent with signature:', signature);

      // Step 5: Confirm the transaction
      const { blockhash, lastValidBlockHeight } = await connection.getLatestBlockhash();
      await connection.confirmTransaction({
        signature,
        blockhash,
        lastValidBlockHeight,
      });
      //console.log('Transaction confirmed successfully.');

      ShowToast({
        type: 'success',
        title: 'Swap Successful!',
        text: (
          <a href={`https://solscan.io/tx/${signature}`} target="_blank" rel="noopener noreferrer">
            View on Solscan
          </a>
        ),
      });


      return signature;
    } catch (error: any) {
      //console.log('Error during swap:', error.message);
      ShowToast({
        type: 'error',
        title: 'Swap Failed',
        text: `Error: ${error.message}`,
      });

      return rejectWithValue(error.message);
    }
  }
);
