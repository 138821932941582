import React from "react";
import { Analytics } from "@vercel/analytics/react";
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { ConnectionProvider, WalletProvider as SolanaWalletProvider } from '@solana/wallet-adapter-react';
import "../../styles/globals.css";
import "antd/dist/antd.css";
import { AppProps } from "next/app";
import { Provider as ReduxProvider } from "react-redux";
import store from "../core/redux/store";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AssetsProvider } from '../context/AssetsProvider';
import { SymbolsProvider } from '../context/SymbolsContext';
import { PinxPriceProvider } from '../context/PinxPriceContext';  
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Script from "next/script";

function MyApp({ Component, pageProps }: AppProps<{}>) {
  const QUICKNODE_HTTP_URL = process.env.NEXT_PUBLIC_QUICKNODE_HTTP_URL;
  const endpoint = QUICKNODE_HTTP_URL || "https://solana.genesysgo.net";

  return (
    <ConnectionProvider endpoint={endpoint}>
      <SolanaWalletProvider autoConnect wallets={[]}>
        <WalletModalProvider>
          <ReduxProvider store={store}>
            <AssetsProvider>
              <SymbolsProvider>
                <PinxPriceProvider>  
                  <div style={{ position: "relative", minHeight: "100vh" }}>
                    {/* Infolinks configuration script */}
                    <Script id="infolinks-config" strategy="lazyOnload">
                      {`
                        window.infolinks_pid = 3428333; 
                        window.infolinks_wsid = 0;
                      `}
                    </Script>
                    {/* Infolinks main script */}
                    <Script
                      src="//resources.infolinks.com/js/infolinks_main.js"
                      strategy="lazyOnload"
                    />
                    <Component {...pageProps} />
                    <ToastContainer />
                    <Analytics />
                  </div>
                </PinxPriceProvider>  
              </SymbolsProvider>
            </AssetsProvider>
          </ReduxProvider>
        </WalletModalProvider>
      </SolanaWalletProvider>
    </ConnectionProvider>
  );
}

export default MyApp;
