import { createAsyncThunk } from '@reduxjs/toolkit';
import ShowToast from '../../../components/ShowToast/ShowToast';

interface FetchConversionRateArgs {
  inputMint: string;
  outputMint: string;
  inputDecimals: number;
  outputDecimals: number;
}

interface FetchConversionRatePayload {
  conversionRate: number | null;
}

export const fetchConversionRateThunk = createAsyncThunk<
  FetchConversionRatePayload,
  FetchConversionRateArgs
>(
  'swap/fetchConversionRate',
  async ({ inputMint, outputMint, inputDecimals, outputDecimals }, { rejectWithValue }) => {
    try {
      // Check if inputMint and outputMint are the same
      if (inputMint === outputMint) {
        // Return 0 as the conversion rate if both mints are the same
        return { conversionRate: 0 };
      }

      // Always use 1 unit of the token, converted to its smallest unit
      const amount = Math.pow(10, inputDecimals); // Convert 1 full token to the smallest unit

      if (!inputMint || !outputMint || isNaN(amount)) {
        // Show error if invalid inputs
        ShowToast({
          type: 'error',
          title: 'Invalid Inputs',
          text: 'Please provide valid input and output mint addresses and decimals.',
        });
        return { conversionRate: 0 };
      }

      // Fetch conversion rate from the Jupiter API
      const url = `https://quote-api.jup.ag/v6/quote?inputMint=${inputMint}&outputMint=${outputMint}&amount=${amount}`;
      const response = await fetch(url);

      if (!response.ok) {
        const errorData = await response.json();
        ShowToast({
          type: 'error',
          title: 'Error Fetching Conversion Rate',
          text: errorData.error || 'There was a problem fetching the conversion rate.',
        });
        return { conversionRate: 0 };
      }

      const data = await response.json();

      if (data && data.outAmount) {
        // Adjust both inAmount and outAmount using their respective decimals
        const inAmount = parseFloat(data.inAmount) / Math.pow(10, inputDecimals);
        const outAmount = parseFloat(data.outAmount) / Math.pow(10, outputDecimals);

        if (isNaN(inAmount) || isNaN(outAmount)) {
          ShowToast({
            type: 'error',
            title: 'Invalid Conversion Data',
            text: 'The conversion data received is invalid.',
          });
          return { conversionRate: 0 };
        }

        // Calculate conversion rate: how much output token per 1 input token
        const conversionRate = outAmount / inAmount;

        return { conversionRate };
      } else {
        return { conversionRate: 0 }; // No valid conversion rate, return 0
      }
    } catch (error: any) {
      ShowToast({
        type: 'error',
        title: 'Conversion Error',
        text: error.message || 'An error occurred while fetching the conversion rate.',
      });
      return { conversionRate: 0 };
    }
  }
);
