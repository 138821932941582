import { configureStore } from '@reduxjs/toolkit';
import swapReducer from './slices/swapSlice'
import balanceReducer from './slices/balancesSlice'
import conversionRateReducer from './slices/conversionRateSlice'

// Configure the store
const store = configureStore({
  reducer: {
    swap: swapReducer, 
    balances: balanceReducer,
    conversionRate: conversionRateReducer
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
