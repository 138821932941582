import { toast, Slide, ToastPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ShowToast = ({ type, title, text, darkMode = true }) => {
  const options = {
    position: 'top-right' as ToastPosition, 
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    theme: darkMode ? 'dark' : 'light',
    transition: Slide,
  };

  switch (type) {
    case 'success':
      toast.success(
        <div>
          <strong>{title}</strong>
          <div>{text}</div>
        </div>,
        options
      );
      break;
    case 'error':
      toast.error(
        <div>
          <strong>{title}</strong>
          <div>{text}</div>
        </div>,
        options
      );
      break;
    case 'info':
      toast.info(
        <div>
          <strong>{title}</strong>
          <div>{text}</div>
        </div>,
        options
      );
      break;
    case 'warning':
      toast.warning(
        <div>
          <strong>{title}</strong>
          <div>{text}</div>
        </div>,
        options
      );
      break;
    default:
      toast(
        <div>
          <strong>{title}</strong>
          <div>{text}</div>
        </div>,
        options
      );
  }
};

export default ShowToast;
