//NOTE: use metroa directly for a backup

import React, { useState, useEffect, createContext, useContext, ReactNode } from 'react';
import SymbolsContext from './SymbolsContext';

interface PinxData {
  price: string;
  priceSol: string;
  dailyPercentageChange: string;
}

interface PinxPriceProviderProps {
  children: ReactNode;
}

const CACHE_KEY = 'pinxPriceData';

const defaultPinxData: PinxData = {
  price: '0.00012590',
  priceSol: '0',
  dailyPercentageChange: '0',
};

const PinxPriceContext = createContext<PinxData>(defaultPinxData);

export const PinxPriceProvider: React.FC<PinxPriceProviderProps> = ({ children }) => {
  const { stockData } = useContext(SymbolsContext);
  const [pinxData, setPinxData] = useState<PinxData>(defaultPinxData);

  useEffect(() => {
    if (stockData && stockData.PINX) {
      const { latestPrice, pinxPriceInSol, dailyPercentageChange } = stockData.PINX;

      const newPinxData: PinxData = {
        price: latestPrice || defaultPinxData.price,
        priceSol: pinxPriceInSol || defaultPinxData.priceSol,
        dailyPercentageChange: dailyPercentageChange || defaultPinxData.dailyPercentageChange,
      };

      setPinxData(newPinxData);
      localStorage.setItem(CACHE_KEY, JSON.stringify(newPinxData));
    } else {
      fallbackToCache();
    }
  }, [stockData]);

  const fallbackToCache = () => {
    if (typeof window !== 'undefined') {
      const cachedData = localStorage.getItem(CACHE_KEY);
      if (cachedData) {
        const parsedData = JSON.parse(cachedData);
        setPinxData({
          price: parsedData.price || defaultPinxData.price,
          priceSol: parsedData.priceSol || defaultPinxData.priceSol,
          dailyPercentageChange: parsedData.dailyPercentageChange || defaultPinxData.dailyPercentageChange,
        });
      } else {
        setPinxData(defaultPinxData);
      }
    }
  };

  return (
    <PinxPriceContext.Provider value={pinxData}>
      {children}
    </PinxPriceContext.Provider>
  );
};

export const usePinxPrice = () => useContext(PinxPriceContext);
