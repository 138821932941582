import { createAsyncThunk } from '@reduxjs/toolkit';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { Connection, LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';
import ShowToast from '../../../components/ShowToast/ShowToast';

interface TokenBalance {
  mint: string;
  balance: number;
}

interface FetchAllBalancesArgs {
  connection: Connection;
  publicKey: PublicKey;
}

interface FetchAllBalancesPayload {
  solBalance: number;          // Balance of native SOL
  tokenBalances: TokenBalance[]; // Array of SPL token balances
}

export const fetchAllBalancesThunk = createAsyncThunk<FetchAllBalancesPayload, FetchAllBalancesArgs>(
  'wallet/fetchAllBalances',
  async ({ connection, publicKey }, { rejectWithValue }) => {
    try {
      // Fetch native SOL balance
      const solBalanceLamports = await connection.getBalance(publicKey);
      const solBalance = solBalanceLamports / LAMPORTS_PER_SOL;

      // Fetch SPL token accounts
      const tokenAccounts = await connection.getParsedTokenAccountsByOwner(
        publicKey,
        { programId: TOKEN_PROGRAM_ID }
      );

      // Parse SPL token balances
      const tokenBalances: TokenBalance[] = tokenAccounts.value.map((accountInfo) => {
        const parsedAccountInfo = accountInfo.account.data.parsed;
        const mint = parsedAccountInfo.info.mint;
        const balance = parsedAccountInfo.info.tokenAmount.uiAmount;

        return { mint, balance };
      });

      // Return both SOL and SPL token balances
      return { solBalance, tokenBalances };
    } catch (error) {
      // Show error toast
      ShowToast({
        type: 'error',
        title: 'Balance Fetch Error',
        text: 'Failed to fetch balances. Please try again later.',
      });

      // Reject with error message
      return rejectWithValue((error as Error).message);
    }
  }
);
