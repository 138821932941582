import { createSlice } from '@reduxjs/toolkit';
import { fetchAllBalancesThunk } from '../thunks/fetchBalancesThunk';

const initialState = {
  solBalance: 0,
  tokenBalances: [],
  loading: false,
  error: null,
};

const balancesSlice = createSlice({
  name: 'balances',
  initialState,
  reducers: {
    resetBalances: (state) => {
      state.solBalance = 0;
      state.tokenBalances = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllBalancesThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllBalancesThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.solBalance = action.payload.solBalance;
        state.tokenBalances = action.payload.tokenBalances;
      })
      .addCase(fetchAllBalancesThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetBalances } = balancesSlice.actions;
export default balancesSlice.reducer;
