import { createSlice } from '@reduxjs/toolkit';
import { fetchConversionRateThunk } from '../thunks/fetchConversionRateThunk';

// Define the shape of the conversion rate state
interface ConversionRateState {
  conversionRate: number | null;
  isGettingConversionRate: boolean;
  conversioRateError: string | null;
}

// Initial state
const initialState: ConversionRateState = {
  conversionRate: null,
  isGettingConversionRate: false,
  conversioRateError: null,
};

// Create a slice to manage the conversion rate state
const conversionRateSlice = createSlice({
  name: 'conversionRate',
  initialState,
  reducers: {
    // Optional: define any extra reducers for other actions here
  },
  extraReducers: (builder) => {
    builder
      // Handle the pending state
      .addCase(fetchConversionRateThunk.pending, (state) => {
        state.isGettingConversionRate = true;
        state.conversioRateError = null; // Reset the error when fetching starts
      })
      // Handle the fulfilled state
      .addCase(fetchConversionRateThunk.fulfilled, (state, action) => {
        state.isGettingConversionRate = false;
        state.conversionRate = action.payload.conversionRate;
      })
      // Handle the rejected state
      .addCase(fetchConversionRateThunk.rejected, (state, action) => {
        state.isGettingConversionRate = false;
        state.conversioRateError = action.payload as string;
      });
  },
});

// Export the reducer to be used in the store
export default conversionRateSlice.reducer;
